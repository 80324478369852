import { Component, HostListener, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { CartService } from 'src/app/shared/services/cart.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { NgbDropdown, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
@Component({
	selector: 'app-dropdown-cart',
	templateUrl: './dropdown-cart.component.html',
	styleUrls: ['./dropdown-cart.component.scss']
})
export class DropdownCartComponent implements OnInit {

	cartItems: any = [];
	cartItemTotal: number = 0;
	totalCartItem: number = 0;
	shippingCharges: number = 0;
	totalDiscount: number = 0;
	finalTotal: number = 0;
	taxCharges: number = 0;
	routerSub:Subscription;
	@ViewChild('open') open: NgbDropdown;
	
	public config: PerfectScrollbarConfigInterface = {};

	@ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;

	constructor(
		private router: Router,
		private cartService: CartService,
		private render: Renderer2
	) {
		this.cartService.products.subscribe((products) => {
			this.totalCartItem = cartService.totalItems;
		});
		this.router.events.subscribe((event) => {
			if(event instanceof NavigationEnd){				
				if(this.open){
					this.open.close();
				}
			  
			}
		});
	}

	ngOnInit(): void {
		this.cartService.products.subscribe(products => {			
			//this.totalCartItem = this.cartService.totalItems;			
			this.cartItems = products;
			this.cartItemTotal = this.cartService.totalPrice;
			this.taxCharges = this.cartService.taxCharges;
			this.shippingCharges = this.cartService.shippingCharges;
			this.finalTotal = this.cartService.finalTotal;
			this.totalDiscount = this.cartService.totalDiscount;
			//console.log(this.totalDiscount);
			this.cartItems?.forEach(e => {
				e.customVariants = '';
				e.product?.variants?.attributes?.forEach(eachVariant => {
					e.customVariants += "<span class='option'>" + eachVariant.attribute_name + ": " + eachVariant.attribute_value_name + "</span>";
				});
			});
		})
		
	}
	
	hideDrop(event: MouseEvent, drop: any) {		
		let anchor: NgbDropdownToggle = drop._anchor;
		if(event.offsetX > anchor.nativeElement.offsetWidth){
			drop.close();
		}
		if(event.offsetY < anchor.nativeElement.offsetTop){
			drop.close();
		}
		if(event.offsetX < anchor.nativeElement.offsetLeft){
			drop.close();
		}
	}

	incQnt(slug, currentQuantity) {
		// this.cartService.increaseQuantity(slug, currentQuantity);
	}

	decQnt(slug, currentQuantity) {
		// this.cartService.decreaseQuantity(slug, currentQuantity);
	}

	deleteCartItem(product_slug, cart_id, cart_session_id, seller_id) {
		this.cartService.removeProduct(product_slug, cart_id, cart_session_id, seller_id);
	}

}
