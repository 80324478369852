
<div class="page-wrapper">
    <app-header></app-header>

    <div id="main-container">
        <router-outlet></router-outlet>
    </div>
    
    <app-footer></app-footer>
</div>
