
	
	<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="register-form" autocomplete="off" [hidden]="hideRegistrationForm" #formRegister>
		<div class="form-row">
			<div class="col-12 form-group ml-auto mr-auto">
				<label for="name" class="sr-only">Name</label>
				<input type="text" placeholder="Name" class="form-control" id="name" formControlName="name">
			</div><!-- form-group end.// -->
			<div class="col-12 form-group ml-auto mr-auto">
				<label for="email" class="sr-only">Email</label>
				<input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
				<span class="invalid-feedback" *ngIf="registerForm.get('email').errors?.serverError || errorField">{{ registerForm.get('email').errors?.serverError }}</span>
			</div> <!-- form-group end.// -->
			<div class="col-12 form-group ml-auto mr-auto">
				<label for="mobileNumber" class="sr-only">Mobile Number</label>
				{{registerForm.get('mobile_number').errors?.serverError}}
				<input type="text" placeholder="Mobile Number" class="form-control" id="mobileNumber" formControlName="mobile_number"> 
				<span class="invalid-feedback" *ngIf="!registerForm.get('mobile_number').valid && !registerForm.get('mobile_number').errors?.serverError && registerForm.get('mobile_number').touched" class="invalid-feedback">Please enter valid mobile number</span>
				<span *ngIf="registerForm.get('mobile_number').errors?.serverError || errorField" class="invalid-feedback">{{ registerForm.get('mobile_number').errors?.serverError }}</span>
			</div><!-- form-group end.// -->
			<div class="col-12 form-group ml-auto mr-auto">
				<label for="password" class="sr-only">Password</label>
				<input type="password" class="form-control" placeholder="Password" id="password" formControlName="password">
			</div><!-- form-group end.// -->
			<div class="col-12 form-group ml-auto mr-auto">
				<label for="password" class="sr-only">Confirm Password</label>
				<input type="password" class="form-control" placeholder="Confirm Password" id="repeatPassword" formControlName="confirm_password">
			</div><!-- form-group end.// -->

			<div class="col-12 form-group ml-auto mr-auto"> 
				<label class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" id="acceptTerms" formControlName="acceptTerms"> 
					<div class="custom-control-label"> By registering, I agree to the <a routerLink="terms-and-condition" target="_blank" style="color: rgb(214, 33, 41);">Terms of Service</a> and <a routerLink="privacy-policy" target="_blank" style="color: rgb(214, 33, 41);">Privacy policy </a></div>
				</label>
			</div>
			<div class="col-12 ml-auto mr-auto text-center">
				<div class="text-center">
					<button type="submit" [disabled]="!registerForm.valid" class="btn btn-primary w-100"> Register  </button>
					<!-- <p class="mt-2">Already Have account? <a routerLink="/login" class="text-underline">Login</a></p> -->
				</div> <!-- form-group// -->      
				<!-- <p class="text-muted">By clicking the 'Sign Up' button, you confirm that you accept our Terms of use and Privacy Policy.</p> -->
			</div> 
			
		</div> <!-- form-row end.// -->

	</form>
	
	<form [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()" class="otp-form" autocomplete="off" [hidden]="hideOtpForm" #formOtp>
		<div class="form-row">
			<div class="col-12 form-group form-holder ml-auto mr-auto">
				<label for="mobileOTP">Verify your Mobile OTP</label>
				<div class="input-group align-items-center">
					<i class="fa fa-check"></i>
					<input type="email" class="form-control" id="mobileOTP" formControlName="mobile_otp" maxlength="6">
				</div>
				<span class="invalid-feedback" *ngIf="otpForm.get('mobile_otp').errors?.serverError">{{ otpForm.get('mobile_otp').errors?.serverError }}</span>
			</div> <!-- form-group end.// -->
			<div class="col-12 form-group form-holder ml-auto mr-auto">
				<label for="emailOTP">Verify your Email OTP</label>
				<div class="input-group align-items-center">
					<i class="fa fa-check"></i>
					<input type="email" class="form-control" id="emailOTP" formControlName="email_otp" maxlength="6">
				</div>
				<span class="invalid-feedback" *ngIf="otpForm.get('email_otp').errors?.serverError">{{ otpForm.get('email_otp').errors?.serverError }}</span>
			</div> <!-- form-group end.// -->
			
			<div class="col-12 mt-3 ml-auto mr-auto text-center">
				<div class="btn-wraper justify-content-center">
					<button type="submit" [disabled]="!otpForm.valid" class="btn btn-primary w-100"> Verify
					</button>
				</div> <!-- form-group// -->
			</div>
			
		</div> <!-- form-row end.// -->

	</form>

<!-- <div class="modal-footer">
<button type="button" class="btn btn-outline-primary">Submit</button>
</div> -->
