export const DEFAULT_META_DATA = {
    // Meta information
    // Global
    metaInfo: {
        googleSiteVerification: 'Tradesala-Google-Site-Verification-Code',
        title: `Tradesala`,
        description: `Tradesala - Indian based B2BC E-Commerce Company. Our Vision is to empower Indian products and becoming India’s finest online marketplace. We are inclined towards the procurement of suppliers from various streams and providing them a better and easier platform to conduct their business online effectively with their customers.`,
        keywords: '',
        og_title: 'Tradesala',
        og_sitename: 'Tradesala',
        og_url: '',
        og_description: `Tradesala - Indian based B2BC E-Commerce Company. Our Vision is to empower Indian products and becoming India’s finest online marketplace. We are inclined towards the procurement of suppliers from various streams and providing them a better and easier platform to conduct their business online effectively with their customers.`,
        og_type: 'website',
        og_image: '',
        og_locale: 'en_US',
        twt_card: 'summary',
        twt_site: '@tradesala',
        twt_url: '',
        twt_title: 'Tradesala',
        twt_description: `Tradesala - Indian based B2BC E-Commerce Company. Our Vision is to empower Indian products and becoming India’s finest online marketplace. We are inclined towards the procurement of suppliers from various streams and providing them a better and easier platform to conduct their business online effectively with their customers.`,
        twt_image: '',
        robots: 'all, index, follow'
    }
};
