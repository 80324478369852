

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
	<div class="form-row">						
        <div class="col-12 form-group ml-auto mr-auto">
			<label for="username" class="font-weight-bold">Email or Mobile</label>
			<input type="text" formControlName="username" class="form-control" placeholder="" id="username" aria-describedby="userName">
		</div> <!-- form-group end.// -->
        
        <div class="col-12 form-group ml-auto mr-auto">
			<label for="password" class="d-flex justify-content-between font-weight-bold"><span>Password</span> <span> <a (click)="forgetPassword(content)" class="text-underline">Forgot Password?</a></span></label>
			<input type="password" formControlName="password" required class="form-control" placeholder="" id="password">
		</div> <!-- form-group end.// -->
		
		<div class="col-12 form-group ml-auto mr-auto"> 
            <label class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" name="terms" checked=""> 
                <div class="custom-control-label"> Remember Me </div> 
            </label>
        </div>

        <div class="col-12 ml-auto mr-auto text-center">
			<div class="text-center">
				<button type="submit" [disabled]="!loginForm.valid" class="btn btn-outline-primary w-100"> Login  </button>
			</div> <!-- form-group// -->      
			<!-- <p class="mt-2">Create an account? <a [routerLink]="['/register']" class="text-underline">Register</a></p> -->
			
		</div> 
								
	</div> <!-- form-row end.// -->
	                                 
</form>

<!-- <div class="modal-footer">
<button type="button" class="btn btn-outline-primary">Submit</button>
</div> -->

<ng-template #content let-modal>
  <div class="modal-header border-0">
    <h4 class="modal-title" id="modal-basic-title">Forgot Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="w-100" [formGroup]="passwordForget" (ngSubmit)="onSubmitForget()">
    	<div class="form-row">
    		<div class="col-12 form-group ml-auto mr-auto">
				<label for="email">Email</label>
				<input type="email" class="form-control" id="email" formControlName="email">
			</div> <!-- form-group end.// -->
    	</div>
      <div class="text-center">
        <button type="submit" class="btn btn-outline-primary" >Submit</button>
      </div>     
    </form>
  </div>
  
</ng-template>