import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';

import { DefaultComponent } from './layout/default/default.component';

import { HeaderComponent } from './header/header.component';
import { DropdownCartComponent } from './header/dropdown-cart/dropdown-cart.component';
import { CollapseCatMenuComponent } from './header/collapse-cat-menu/collapse-cat-menu.component';
import { SearchComponent } from './header/search/search.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { AuthInterceptorProvider } from './core/interceptors/auth.interceptor';
import { LoaderInterceptorProvider } from './core/interceptors/loader.interceptor';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    DropdownCartComponent,
    CollapseCatMenuComponent,
    SearchComponent,
  ],
  imports: [    
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({     
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    WINDOW_PROVIDERS,
    AuthInterceptorProvider,
    LoaderInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
