import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  apiURL: string = environment.baseUrl;
  bearer_token: string;
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  baseRequestOptions(): object {
    
    if (isPlatformBrowser(this.platformId)){
			const userData = JSON.parse(localStorage.getItem('userData'));
      this.bearer_token = _.get(userData, '_token', null);
		}
    

    return {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Accept': 'application/json',
        'Access-Control-Expose-Headers': '*',
        'Authorization': `Bearer ${this.bearer_token}`
        // 'x-api-key': _.get(this.environment, 'xApiToken', ""),
      }),
      withCredentials: true,
      observe: 'response',
    }
  }
  
  getAll<T>(path: string): Observable<T> {
    return this.http.get<T>(`${this.apiURL}/${path}`);
  }
  
  get<T>(path: string): Observable<T> {    
    return this.http.get<T>(`${this.apiURL}/${path}`);
  }
  getFilters<T>(path: string, filters: {}): Observable<T> { 
    //const params = new HttpParams(filters); 
    return this.http.get<T>(`${this.apiURL}/${path}`, filters);
  }

  postApi(path: string, data?: object): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/${path}`, data, this.baseRequestOptions());
  }

  patchApi(path: string, data?: object): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}/${path}`, data);
  }

  deleteApi<T>(path: string, body?: object): Observable<T> {
    let options: object = {
      headers: new HttpHeaders({        
        'Accept': 'application/json',
      }),
      observe: 'body',
      body: body,
      responseType: 'json'
    }  
    
    return this.http.delete<T>(`${this.apiURL}/${path}`, options);
  } 
}