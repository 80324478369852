
    <div class="modal-header border-0">
        <div class="d-flex w-100 order-0">
            <div class="col-12 p-0">
                <h4 class="modal-title" id="modal-basic-title">Login</h4>
                <p class="mb-0">New to Tradesala? <a (click)="registerPopup()" class="font-weight-bold">Create an account</a></p>
            </div>
            
        </div>
        <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body centered">
       <app-modal-login></app-modal-login>
    </div>
 